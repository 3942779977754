export const REMOVE_TOKEN = 'REMOVE_TOKEN';

export const WS_INITIALIZE_CHANNEL = 'WS_INITIALIZE_CHANNEL';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS_CONTINUE = 'LOGIN_SUCCESS_CONTINUE';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_PROFILE_REQUEST = 'GET_PROFILE_REQUEST';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const WS_PROFILE_UPDATED = 'WS_PROFILE_UPDATED';
export const WS_TIME_REMAINING = 'WS_TIME_REMAINING';
export const WS_ACCOUNT_BLOCKED = 'WS_ACCOUNT_BLOCKED';

export const SET_TIME_REMAINING = 'SET_TIME_REMAINING';

export const ADD_CARD_REQUEST = 'ADD_CARD_REQUEST';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';

export const EDIT_CARD_REQUEST = 'EDIT_CARD_REQUEST';
export const EDIT_CARD_SUCCESS = 'EDIT_CARD_SUCCESS';

export const DELETE_CARD_REQUEST = 'DELETE_CARD_REQUEST';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAILURE = 'DELETE_CARD_FAILURE';

export const GET_REFILL_REQUEST = 'GET_REFILL_REQUEST';
export const GET_REFILL_SUCCESS = 'GET_REFILL_SUCCESS';
