import { Box, Typography, makeStyles, Grid } from '@material-ui/core';
import React from 'react';
import logoWhite from '../../../../assets/images/logo-white.svg';

const useStyles = makeStyles(() => ({
  title: {},
  text: () => ({
    fontSize: 12,
    color: 'white',
    margin: 'auto',
    marginBottom: 40,
    lineHeight: 1,
  }),
  footer: {
    fontSize: 11,
    color: 'white',
    fontWeight: 'normal',
    textTransform: 'uppercase',
    '& svg': {
      maxWidth: 80,
    },
  },
  logo: {
    maxWidth: 90,
    marginTop: 5,
  },
}));

const DarkModalContent = ({ title, text, withFooter }) => {
  const classes = useStyles();

  return (
    <Box minWidth={250}>
      <Typography variant="h5" component="h2" color="secondary">
        <Box className="fontFamilyBook" lineHeight="1" mb={2}>
          {title}
        </Box>
      </Typography>
      <Typography className={classes.text}>
        <div className="fontFamilyBook">{text}</div>
      </Typography>
      {withFooter && (
        <Grid container direction="column" className={classes.footer}>
          <Grid item>
            <Box className="fontFamilyLight">Thank you for choosing</Box>
          </Grid>
          <Grid item>
            <img src={logoWhite} alt="The Que logo" className={classes.logo} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DarkModalContent;
