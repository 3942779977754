const clearValuesFromDefault = values => {
  const valuesToReturn = { ...values };
  if (valuesToReturn.firstName === 'First Name') {
    valuesToReturn.firstName = '';
  }
  if (valuesToReturn.lastName === 'Last Name') {
    valuesToReturn.lastName = '';
  }
  return valuesToReturn;
};

export default clearValuesFromDefault;
