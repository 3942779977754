import {
  // Button, Toolbar, Box, Grid, Typography,
  Container,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { NavLink, useHistory } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import bg from '../../../assets/images/background.png';

// import timetIcon from '../../../assets/images/bar-time.svg';
// import accountIcon from '../../../assets/images/bar-account.svg';
// import chatIcon from '../../../assets/images/bar-chat.svg';
// import logoutIcon from '../../../assets/images/bar-logout.svg';
// import { paths } from '../../../config';
// import * as actions from '../../profile/state/actions';
// import * as selectors from '../../profile/state/selectors';
// import * as actionsLayout from '../state/actions';

const useStyles = makeStyles(theme => ({
  // link: {
  //   color: theme.palette.primary.main,
  //   marginRight: theme.spacing(1.5),
  //   textTransform: 'uppercase',
  //   '&:hover': {
  //     color: 'inherit',
  //     '& img': {
  //       display: 'none',
  //     },
  //   },
  //   display: 'inline-block',
  //   fontSize: 9,
  //   maxWidth: 50,
  //   textAlign: 'center',
  //   position: 'relative',
  // },
  // icon: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  //   margin: 'auto',
  //   maxWidth: 25,
  //   background: theme.palette.primary.main,
  // },
  // logout: {
  //   fontSize: 9,
  //   maxWidth: 50,
  //   minWidth: 'unset',
  //   padding: 0,
  //   display: 'inline-block',
  //   position: 'relative',
  //   color: theme.palette.primary.main,
  //   '&:hover': {
  //     background: 'inherit',
  //     color: 'inherit',
  //     '& img': {
  //       display: 'none',
  //     },
  //   },
  // },
  // navInner: {
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'space-evenly',
  //   minHeight: 50,
  //   background: theme.palette.primary.main,
  //   maxWidth: 350,
  //   margin: 'auto',
  //   position: 'fixed',
  //   left: 0,
  //   right: 0,
  //   zIndex: 1,
  //   color: 'white',
  //   paddingRight: 15,
  //   paddingTop: 4,
  // },
  container: {
    paddingTop: 'calc(50px + 32px)',
    paddingBottom: theme.spacing(4),
    maxWidth: 820,
  },
  // logo: {
  //   verticalAlign: 'middle',
  //   fill: theme.palette.secondary.main,
  //   marginLeft: 30,
  //   '&:hover': {
  //     fill: '#fff',
  //   },
  // },
  // title: () => ({
  //   fontSize: 16,
  //   color: 'white',
  //   maxWidth: 165,
  //   margin: 'auto',
  //   marginBottom: 40,
  // }),
  // button: {
  //   color: 'white',
  //   borderColor: 'white',
  // },
  // time: {
  //   fontSize: 27,
  // },
  tempBody: {
    backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    minHeight: '100vh',
  },
}));

// const LogoutDialogContent = ({ closeDialog, logout }) => {
//   const classes = useStyles();

//   return (
//     <Box minWidth={250}>
//       <Typography variant="h6" component="h2" color="secondary">
//         <Box className="fontFamilyBook">FOR REAL?</Box>
//       </Typography>
//       <Typography className={classes.title}>ARE YOU LEAVING ALREADY?</Typography>
//       <Grid container spacing={1}>
//         <Grid item xs={6}>
//           <Button fullWidth variant="outlined" onClick={logout} className={classes.button}>
//             <Box fontSize={11}>Yes!</Box>
//           </Button>
//         </Grid>
//         <Grid item xs={6}>
//           <Button
//             fullWidth
//             color="secondary"
//             variant="contained"
//             onClick={closeDialog}
//             style={{ paddingRight: 10, paddingLeft: 10 }}
//           >
//             <Box fontSize={11}>HMMM, NOT YET.</Box>
//           </Button>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

const Layout = ({ children }) => {
  const classes = useStyles();

  // const dispatch = useDispatch();
  // const history = useHistory();
  // const timeRemaining = useSelector(selectors.timeRemaining);

  // const closeDialog = () => dispatch(actionsLayout.dialogRemove());

  // const logout = () => {
  //   dispatch(actions.LogoutRequest({ history }));
  //   closeDialog();
  // };

  // const handleLogoutClick = () => {
  //   dispatch(
  //     actionsLayout.dialogSet({
  //       content: <LogoutDialogContent closeDialog={closeDialog} logout={logout} />,
  //       darkStyle: true,
  //     }),
  //   );
  // };

  return (
    <div className={classes.tempBody}>
      {/* <Toolbar className={classes.navInner}>
        <Box pr={3} component="span" style={{ verticalAlign: 'middle' }}>
          <Typography
            variant="h1"
            component="span"
            color="secondary"
            className={`accentFont ${classes.time}`}
          >
            {timeRemaining.slice(0, -3) || '--'}
          </Typography>
        </Box>
        <NavLink to={paths.addTime} className={classes.link}>
          add Time
          <img src={timetIcon} alt="" className={classes.icon} />
        </NavLink>
        <NavLink to={paths.account} className={classes.link}>
          my account
          <img src={accountIcon} alt="" className={classes.icon} />
        </NavLink>
        <NavLink to={paths.chat} className={classes.link}>
          help chat
          <img src={chatIcon} alt="" className={classes.icon} />
        </NavLink>
        <Button
          color="inherit"
          className={`${classes.logout} fontFamilyLight`}
          onClick={handleLogoutClick}
        >
          Log out
          <img src={logoutIcon} alt="" className={classes.icon} />
        </Button>
        <svg
          width="28"
          height="32"
          xmlns="http://www.w3.org/2000/svg"
          className={classes.logo}
          viewBox="0 0 35 30"
        >
          <path d="M0 0h5.45v26.55H0z" />
          <path d="M26.55 0v5.45H0V0zM17.02 21.1v5.45H0V21.1z" />
          <path d="M27.92 32h-5.45V0h5.45z" />
        </svg>
      </Toolbar> */}
      <Container maxWidth="md" className={classes.container}>
        {children}
      </Container>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
