/* eslint-disable no-console */
import React from 'react';
import { all, put, call, takeEvery } from 'redux-saga/effects';
import { paths } from '../../../config';
import { clearToken, setToken, getMacAddress } from '../../../utils/auth';
import getStringFromError from '../../../utils/getStringFromError';
import showNotification from '../../../utils/showNotification';
import DarkModalContent from '../../layout/componetns/Dialog/DarkModalContent';
import RefillModal from '../components/RefillModal';
import * as actionsLayout from '../../layout/state/actions';
import * as types from './types';
import * as actions from './actions';
import * as api from '../api';

function* onLoginSuccess({ payload }) {
  const { data, history } = payload;

  setToken(data.accessToken);
  yield put(actions.LoginSuccess(data));
  history.push('/');
}

function* loginUser({ payload }) {
  const { values, history, resetForm } = payload;

  try {
    const { data } = yield call(api.authenticate, { ...values, mac: getMacAddress() || ''});

    const { isSingleUse, accountSetupFinished } = data.user;

    // only permanent CLIENT user:
    // if the user has no time, show pop-up "We're sorry no time left" and block click outside of modal
    // but if it is first login (user has emptyFields), the user must first be able to fill out the account setup form
    // if (time === '00:00:00' && !isSingleUse && role === 'CLIENT' && !emptyFields.length) {
    //   yield put(
    //     actionsLayout.dialogSet({
    //       content: <RefillModal data={data} history={history} />,
    //       darkStyle: true,
    //       timeout: 10000,
    //       disableBackdropClick: true,
    //     }),
    //   );
    //   if (resetForm) {
    //     resetForm();
    //   }
    // } else {
    if (accountSetupFinished && !isSingleUse) {
      yield put(
        actionsLayout.dialogSet({
          title: `Hello, ${data.user.firstName}!`,
          content: `It's great to see you:)`,
          timeout: 2000,
        }),
      );
    }
    setToken(data.accessToken);
    yield put(actions.LoginSuccess(data));
    history.push('/');
    // yield put(actions.LoginSuccessContinue({ data, history }));
    // }
  } catch (err) {
    console.error('loginUser', err);

    if (err.response.status === 402) {
      yield put(
        actionsLayout.dialogSet({
          content: <RefillModal oldPayload={payload} />,
          darkStyle: true,
          timeout: 10000,
          disableBackdropClick: true,
        }),
      );
      resetForm();
    } else if (err.response.data.kind) {
      yield put(
        actionsLayout.dialogSet({
          content: (
            <DarkModalContent
              title={err.response.data.title.toUpperCase()}
              text={err.response.data.text}
              withFooter
            />
          ),
          darkStyle: true,
        }),
      );
    } else {
      yield put(actions.LoginFailure(getStringFromError(err)));
    }
  }
}

function* logoutRequest({ payload }) {
  try {
    const { history } = payload;

    yield call(api.logout);

    clearToken();

    yield put(actions.LogoutSuccess());

    history.push(paths.login);
  } catch (err) {
    console.error('logoutRequest', err);

    const errorMessage = getStringFromError(err);

    yield put(actions.LogoutFailure(errorMessage));
  }
}

function* getRefill() {
  try {
    const { data } = yield call(api.getRefill);

    yield put(actions.getRefillSuccess(data));
  } catch (err) {
    console.error('getRefill', err);
  }
}

function* getProfileRequest() {
  try {
    const { data } = yield call(api.getProfile);

    yield put(actions.getProfileSuccess(data));

    // initialize only after we have profile, as we will need id to connect to socket room
    yield put(actions.wsInitializeChannel());
  } catch (err) {
    console.error('getProfileRequest', err);

    const errorMessage = getStringFromError(err);

    yield put(actions.getProfileFailure(errorMessage));
  }
}

function* updateProfileRequest({ payload }) {
  try {
    const { callback, ...values } = payload;
    const { data } = yield call(api.updateProfile, values);

    if (callback) {
      callback();
    }

    yield put(actions.updateProfileSuccess(data));

    // yield put(showNotification('Profile updated.', 'success'));
  } catch (err) {
    console.error('updateProfileRequest', err);

    yield put(showNotification(getStringFromError(err), 'error'));
  }
}

function* addCardRequest({ payload }) {
  try {
    const { callback, successMessage, ...values } = payload;
    const { data } = yield call(api.addCard, values);

    if (callback) {
      callback();
    }

    yield put(actions.addCardSuccess(data));

    // yield put(
    //   actionsLayout.enqueueSnackbar({
    //     message: 'Card added.',
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'success',
    //     },
    //   }),
    // );
  } catch (err) {
    console.error('addCardRequest', err);

    yield put(
      actionsLayout.enqueueSnackbar({
        message: getStringFromError(err),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}
function* editCardRequest({ payload }) {
  try {
    const { callback, successMessage, ...values } = payload;
    const { data } = yield call(api.editCard, values);

    if (callback) {
      callback();
    }

    yield put(actions.editCardSuccess(data));

    // yield put(
    //   actionsLayout.enqueueSnackbar({
    //     message: successMessage || 'Card updated.',
    //     options: {
    //       key: new Date().getTime() + Math.random(),
    //       variant: 'success',
    //     },
    //   }),
    // );
  } catch (err) {
    console.error('editCardRequest', err);

    yield put(
      actionsLayout.enqueueSnackbar({
        message: getStringFromError(err),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}

function* deleteCardRequest({ payload }) {
  try {
    const { callback, ...values } = payload;
    const { data } = yield call(api.deleteCard, values);

    if (callback) {
      callback();
    }

    yield put(
      actionsLayout.enqueueSnackbar({
        message: 'Card deleted.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
        },
      }),
    );

    yield put(actions.deleteCardSuccess(data));
  } catch (err) {
    console.error('deleteCardRequest', err);

    yield put(
      actionsLayout.enqueueSnackbar({
        message: getStringFromError(err),
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
        },
      }),
    );
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(types.LOGIN_REQUEST, loginUser),
    takeEvery(types.LOGIN_SUCCESS_CONTINUE, onLoginSuccess),
    takeEvery(types.LOGOUT_REQUEST, logoutRequest),
    takeEvery(types.GET_PROFILE_REQUEST, getProfileRequest),
    takeEvery(types.UPDATE_PROFILE_REQUEST, updateProfileRequest),
    takeEvery(types.DELETE_CARD_REQUEST, deleteCardRequest),
    takeEvery(types.EDIT_CARD_REQUEST, editCardRequest),
    takeEvery(types.ADD_CARD_REQUEST, addCardRequest),
    takeEvery(types.GET_REFILL_REQUEST, getRefill),
  ]);
}
