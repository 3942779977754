import React from 'react';
import { Button, Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import * as actionsLayout from '../../../layout/state/actions';
import * as actions from '../../state/actions';

const RefillModal = ({ oldPayload }) => {
  const dispatch = useDispatch();
  const onLoginSuccess = () => {
    const { values, history, resetForm } = oldPayload;
    dispatch(actions.LoginRequest({ values: { ...values, force: true }, history, resetForm }));
    dispatch(actionsLayout.dialogRemove());
  };
  return (
    <Box minWidth={250}>
      <Typography variant="h6" component="h2" color="secondary">
        <Box className="fontFamilyBook">We're sorry</Box>
      </Typography>
      <Typography>
        <Box color="white" maxWidth={166} fontWeight={600} margin="auto" marginBottom="1rem">
          YOU DONT HAVE ANY TIME LEFT IN YOUR ACCOUNT
        </Box>
        <Box color="white" fontSize="10px">
          CLICK TO ADD MORE TIME
        </Box>
      </Typography>
      <Button fullWidth color="secondary" variant="contained" onClick={onLoginSuccess}>
        Refill my account
      </Button>
    </Box>
  );
};

export default RefillModal;
