export const GET_ITEMS_REQUEST = 'GET_ITEMS_REQUEST';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';

export const POST_INVOICE_REQUEST = 'POST_INVOICE_REQUEST';
export const POST_INVOICE_SUCCESS = 'POST_INVOICE_SUCCESS';

export const GET_INVOICE_REQUEST = 'GET_INVOICE_REQUEST';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';

export const POST_PAYMENT_REQUEST = 'POST_PAYMENT_REQUEST';
export const POST_PAYMENT_SUCCESS = 'POST_PAYMENT_SUCCESS';

export const CONFIRM_PASSWORD_REQUEST = 'CONFIRM_PASSWORD_REQUEST';
