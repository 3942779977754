import * as PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom';

import AccountSetup from '../AccountSetup';
import Refill from '../Refill';
import { getToken } from '../../utils/auth';
import ProfileContext from '../../providers/ProfileProvider/ProfileContext';
import * as profileSelecotrs from '../../base/profile/state/selectors';
import Layout from '../../base/layout/componetns';
import Loading from '../../base/_common/Loading';
import { paths } from '../../config';

/**
 *  If account missing required fields, it will redirect to AccountSetup
 *  If there is no time left, redirect to refill
 */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = getToken();

  const { isLoggedIn, profile } = useContext(ProfileContext);
  const actualTime = useSelector(profileSelecotrs.timeRemaining);
  const location = useLocation();

  if (!token) {
    return <Redirect {...rest} to={paths.login} />;
  }
  if (!isLoggedIn) {
    return <Loading />;
  }
  const { accountSetupFinished, isSingleUse, unlimitedPlanIssuedAt } = profile;

  if (!accountSetupFinished && !isSingleUse) {
    return <Route to={paths.accountSetup} render={AccountSetup} />;
  }

  if (
    !unlimitedPlanIssuedAt &&
    actualTime === '00:00:00' &&
    !location.pathname.includes('checkout')
  ) {
    return (
      <Route
        to={paths.addTime}
        render={() => (
          <Layout>
            <Refill />
          </Layout>
        )}
      />
    );
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.node.isRequired,
};

export default PrivateRoute;
